<template>
  <v-container>
    <h1>Group Result</h1>
    <div class="d-flex justify-end mb-2">
      <v-btn
        @click="downloadFile(file)"
        color="primary"
        class="mx-1"
        :disabled="file === null"
      >
        Download Result
        <v-icon right dark> mdi-cloud-download </v-icon>
      </v-btn>
      <v-btn
        @click="generateTestResultFile"
        color="primary"
        class="mx-1"
        :loading="generateResultLoading"
      >
        Generate Result
        <v-icon right dark> mdi-file-cog </v-icon>
      </v-btn>
    </div>
    <v-data-table
      :headers="headers"
      :items="results"
      :options.sync="options"
      :loading="loading"
      class="elevation-1"
    >
      <template v-slot:[`item.action`]="{ item: { id, file } }">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark v-bind="attrs" v-on="on">
              Option
              <v-icon right dark> mdi-menu-down </v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              @click="file !== null && downloadFile(file)"
              :disabled="file === null"
              link
            >
              <v-list-item-title>Download Result</v-list-item-title>
            </v-list-item>
            <v-list-item @click="showResetPrompt(id)" link>
              <v-list-item-title>Reset Test</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>

    <v-dialog v-model="dialog" max-width="290">
      <v-card>
        <v-card-title class="headline"> Reset selected test? </v-card-title>

        <v-card-text>
          This action is irreversible. Resetting this test means other data that
          containing this test will also be deleted.
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="error darken-1"
            @click="resetTest()"
            :loading="resetTestLoading"
            text
          >
            Reset
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import ResultService from "@/services/result";
import TestService from "@/services/test";

export default {
  name: "Result.Group.TestResult",
  data() {
    return {
      totalData: 0,
      results: [],
      loading: true,
      options: {},
      headers: [],
      dialog: false,
      resetTestLoading: false,
      selectedRunningTestId: "",
      file: null,
      generateResultLoading: false
    };
  },
  watch: {
    options: {
      handler() {
        this.getGroupResult();
      },
      deep: true
    }
  },
  created() {
    this.setNavbarTitle("Group Result");
    this.findTestResultFile();
  },
  computed: {
    groupId() {
      return this.$route.params.group_id;
    },
    testId() {
      return this.$route.params.test_id;
    }
  },
  methods: {
    ...mapActions("drawer", ["setNavbarTitle"]),
    async getGroupResult() {
      const result = await ResultService.groupResult(this.groupId, this.testId);
      this.headers = [
        {
          text: "Student Name",
          value: "student_name",
          sortable: false
        },
        ...result.data.data.header.map(({ abbreviation }) => ({
          text: abbreviation,
          value: `${abbreviation}.score`,
          sortable: false
        })),
        { text: "Score", value: "test_score", sortable: false },
        { text: "Action", value: "action", sortable: false }
      ];
      this.results = [...result.data.data.result];
      this.loading = false;
    },
    downloadFile(url) {
      const link = document.createElement("a");
      document.body.appendChild(link);
      link.href = url;
      link.click();
    },
    showResetPrompt(runningTestId) {
      this.selectedRunningTestId = runningTestId;
      this.dialog = true;
    },
    async resetTest() {
      try {
        this.resetTestLoading = true;
        await TestService.resetRunningTest(this.selectedRunningTestId);
        this.dialog = false;
        this.loading = true;
        await this.getGroupResult();
      } catch (error) {
        this.resetTestLoading = false;
      }
    },
    async findTestResultFile() {
      const file = await ResultService.testResultFile(
        this.groupId,
        this.testId
      );

      if (file.data.data !== null) {
        this.file = file.data.data.file;
      }
    },
    async generateTestResultFile() {
      try {
        this.generateResultLoading = true;
        const generateResult = await ResultService.generateGroupResult(
          this.groupId,
          this.testId
        );
        this.file = generateResult.data.data.file;
        this.downloadFile(generateResult.data.data.file);
      } finally {
        this.generateResultLoading = false;
      }
    }
  }
};
</script>
