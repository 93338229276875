var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('h1',[_vm._v("Group Result")]),_c('div',{staticClass:"d-flex justify-end mb-2"},[_c('v-btn',{staticClass:"mx-1",attrs:{"color":"primary","disabled":_vm.file === null},on:{"click":function($event){return _vm.downloadFile(_vm.file)}}},[_vm._v(" Download Result "),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v(" mdi-cloud-download ")])],1),_c('v-btn',{staticClass:"mx-1",attrs:{"color":"primary","loading":_vm.generateResultLoading},on:{"click":_vm.generateTestResultFile}},[_vm._v(" Generate Result "),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v(" mdi-file-cog ")])],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.results,"options":_vm.options,"loading":_vm.loading},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
var ref_item = ref.item;
var id = ref_item.id;
var file = ref_item.file;
return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" Option "),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v(" mdi-menu-down ")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"disabled":file === null,"link":""},on:{"click":function($event){file !== null && _vm.downloadFile(file)}}},[_c('v-list-item-title',[_vm._v("Download Result")])],1),_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.showResetPrompt(id)}}},[_c('v-list-item-title',[_vm._v("Reset Test")])],1)],1)],1)]}}],null,true)}),_c('v-dialog',{attrs:{"max-width":"290"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(" Reset selected test? ")]),_c('v-card-text',[_vm._v(" This action is irreversible. Resetting this test means other data that containing this test will also be deleted. ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error darken-1","loading":_vm.resetTestLoading,"text":""},on:{"click":function($event){return _vm.resetTest()}}},[_vm._v(" Reset ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }